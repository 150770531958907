import {mapMutations} from "vuex";

export default {
  name: "promo",
  props: {
    data: {
      type: Object,
      default: ()=> {}
    }
  },
  components:{

  },
  data(){
    return{

    }
  },
  created() {
  },
  computed:{

  },
  methods:{
    ...mapMutations({
    })
  }
}