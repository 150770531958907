import {mapActions, mapGetters, mapMutations} from "vuex";

//sections
import promoSection from '@/modules/home/section/promo/index.vue';
import banner from '@/modules/home/section/banner/index.vue';
import categorySlider from "@/modules/home/section/category-slider/index.vue";
import categoryList from "@/modules/home/section/category-list/index.vue";
import bestseller from "@/modules/home/section/bestseller/index.vue";
import kids from "@/modules/home/section/kids/index.vue";

//mixins
import {dynamicHead} from '@/mixins/dynamic-head.js'
import AOS from 'aos';

import 'aos/dist/aos.css';

export default {
  name: "home",
  mixins: [dynamicHead],
  components:{
    banner,
    categoryList,
    promoSection,
    categorySlider,
    bestseller,
    kids,
  },
  data(){
    return{
      fragmentedBestsellers: [],
      isDesktop: true,
      params: {
        title: '',
        description: '',
        keywords: '',
        image: '',
      },
    }
  },
  created() {
    this.fetchBanner()
    this.fetchPromo()
    this.fetchSelectedCategory()
    this.fetchBestsellers().then(()=>{
      if(this.bestsellers.length){
        this.fragmentedBestsellers = _.chunk(this.bestsellers, 4)
      }
    }).catch(error=>{
      console.log(error)
    })
    this.fetchMeta('home').then(()=>{
      this.setParams()
      this.setMeta()
    }).catch(error=>{
      console.log(error)
    })
  },
  mounted() {
    AOS.init({
      disable: function () {
        const maxWidth = 700;
        return window.innerWidth <= maxWidth;
      }
    });

    window.innerWidth <= 768 ? this.isDesktop = false : this.isDesktop = true;

  },
  computed:{
    ...mapGetters({
      banner: 'home/banner',
      promo: 'home/promo',
      bestsellers: 'product/bestsellers',
      categoriesList: 'category/categories',
      selectedCategory: 'category/selectedCategory',
      meta: 'meta/meta',
    })
  },

  methods:{
    ...mapActions({
      fetchBanner: 'home/GET_BANNER',
      fetchMeta: 'meta/GET_META',
      fetchPromo: 'home/GET_PROMO',
      fetchSelectedCategory: 'category/GET_SELECTED_CATEGORY',
      fetchBestsellers: 'product/GET_BESTSELLERS',
    }),
    ...mapMutations({
    }),
    setParams(){
      this.params.title = this.meta.metaTitle;
      this.params.description = this.meta.metaDescription;
      this.params.keywords = this.meta.metaKeywords;
      this.params.image = this.meta.metaImage;
    }
  }
}