import {mapGetters, mapMutations} from "vuex";
import {
  VueFlux,
  FluxCaption,
  FluxControls,
  FluxIndex,
  FluxButton,
  FluxPagination,
  FluxPreloader,
} from 'vue-flux';
export default {
  name: "banner",
  props: {
    list: {
      type: Array,
      default: ()=> []
    }
  },
  components:{
    VueFlux,
    FluxCaption,
    FluxControls,
    FluxButton,
    FluxIndex,
    FluxPagination,
    FluxPreloader,
  },
  data(){
    return{
      options: {
        allowFullscreen: false,
        allowToSkipTransition: false,
        autohideTime: 1500,
        autoplay: false,
        bindKeys: false,
        delay: 1000,
        enableGestures: false,
        infinite: true,
        lazyLoad: true,
        lazyLoadAfter: 3,
      },
      images: [],
      transitions: [
        'warp',
      ],
    }
  },
  created() {
    this.imageArr()
  },
  mounted() {

  },
  computed:{
    ...mapGetters([
      'currentLanguage',
    ]),
  },
  methods:{
    goNext(){
      this.$refs.slider.show('next')
    },
    goPrev(){
      this.$refs.slider.show('prev')
    },
    redirect(){
      let origin = window.location.origin;
      let locale = this.currentLanguage.key;
      let link = this.list[this.$refs.slider.Images.$current.index].productSlug;
      link !== '' ? window.location.href = `${origin}/${locale}/${link}` : console.log()
    },
    imageArr(){
      this.list.forEach(el=>{
        this.images.push(el.image.toString())
      })
    }
  }
}