import {mapActions, mapGetters, mapMutations} from "vuex";
import {Carousel, Slide} from "vue-carousel";
import bestsellerCard from "@/modules/home/section/bestseller/bestseller-card/index.vue";
export default {
  name: "bestseller",
  props: {
    list: {
      type: Array,
      default: ()=> []
    }
  },
  components:{
    Carousel,
    Slide,
    bestsellerCard
  },
  data(){
    return{
      isDesktop: true,
    }
  },
  created() {
  },
  mounted() {
    window.innerWidth <= 1180 ? this.isDesktop = false : this.isDesktop = true;
  },
  computed:{
    ...mapGetters({
    }),
  },
  methods:{
    ...mapActions({
    }),
    carouselNav($event, direction) {
      const carousel = this.$refs['carousel'];
      carousel.advancePage(direction);
    },
  }
}