export default {
  name : "category-list",
  props: {
    list: {
      type: Array,
      default: ()=> []
    },
    catalog: {
      type: Boolean,
      default: () => false,
    }
  }
}
